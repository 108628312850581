@font-face {
  font-family: 'Sofia Pro';
  font-style: normal;
  font-weight: 400;
  src: local('Sofia Pro'), url('/assets/fonts/Sofia Pro Regular Az.woff') format('woff');
}

@font-face {
  font-family: 'Sofia Pro';
  font-style: italic;
  font-weight: 400;
  src: local('Sofia Pro'), url('/assets/fonts/Sofia Pro Regular Italic Az.woff') format('woff');
}

@font-face {
  font-family: 'Sofia Pro';
  font-style: normal;
  font-weight: 250;
  src: local('Sofia Pro'), url('/assets/fonts/Sofia Pro ExtraLight Az.woff') format('woff');
}

@font-face {
  font-family: 'Sofia Pro';
  font-style: normal;
  font-weight: 250;
  src: local('Sofia Pro'), url('/assets/fonts/Sofia Pro UltraLight Az.woff') format('woff');
}

@font-face {
  font-family: 'Sofia Pro';
  font-style: italic;
  font-weight: 250;
  src: local('Sofia Pro'), url('/assets/fonts/Sofia Pro ExtraLight Italic Az.woff') format('woff');
}

@font-face {
  font-family: 'Sofia Pro';
  font-style: italic;
  font-weight: 250;
  src: local('Sofia Pro'), url('/assets/fonts/Sofia Pro UltraLight Italic Az.woff') format('woff');
}

@font-face {
  font-family: 'Sofia Pro';
  font-style: normal;
  font-weight: 300;
  src: local('Sofia Pro'), url('/assets/fonts/Sofia Pro Light Az.woff') format('woff');
}

@font-face {
  font-family: 'Sofia Pro';
  font-style: italic;
  font-weight: 300;
  src: local('Sofia Pro'), url('/assets/fonts/Sofia Pro Light Italic Az.woff') format('woff');
}

@font-face {
  font-family: 'Sofia Pro';
  font-style: normal;
  font-weight: 500;
  src: local('Sofia Pro'), url('/assets/fonts/Sofia Pro Medium Az.woff') format('woff');
}

@font-face {
  font-family: 'Sofia Pro';
  font-style: italic;
  font-weight: 500;
  src: local('Sofia Pro'), url('/assets/fonts/Sofia Pro Medium Italic Az.woff') format('woff');
}

@font-face {
  font-family: 'Sofia Pro';
  font-style: normal;
  font-weight: 600;
  src: local('Sofia Pro'), url('/assets/fonts/Sofia Pro Semi Bold Az.woff') format('woff');
}

@font-face {
  font-family: 'Sofia Pro';
  font-style: italic;
  font-weight: 600;
  src: local('Sofia Pro'), url('/assets/fonts/Sofia Pro Semi Bold Italic Az.woff') format('woff');
}

@font-face {
  font-family: 'Sofia Pro';
  font-style: normal;
  font-weight: 700;
  src: local('Sofia Pro'), url('/assets/fonts/Sofia Pro Bold Az.woff') format('woff');
}

@font-face {
  font-family: 'Sofia Pro';
  font-style: italic;
  font-weight: 700;
  src: local('Sofia Pro'), url('/assets/fonts/Sofia Pro Bold Italic Az.woff') format('woff');
}

@font-face {
  font-family: 'Sofia Pro';
  font-style: normal;
  font-weight: 900;
  src: local('Sofia Pro'), url('/assets/fonts/Sofia Pro Black Az.woff') format('woff');
}

@font-face {
  font-family: 'Sofia Pro';
  font-style: italic;
  font-weight: 900;
  src: local('Sofia Pro'), url('/assets/fonts/Sofia Pro Black Italic Az.woff') format('woff');
}
