/*
 * colors from design manual
 * to be used only in this file to map to `named colors`
 * fargene er definert fra designmanual utformet av uniform.
 * se:
 * https://design.miljodirektoratet.no/profilbank/miljodirektoratet/designmanual/
 * https://xd.adobe.com/view/7302919d-1bac-469f-93f4-da4b5ebb35d9-2651/screen/5e8920d8-13c4-44f4-b0eb-05763e64ffc9/specs/
 *
*/

/*
 * NB. Using variables with web components variables (--variable-name) requires interpolation.
 * Ref: https: //sass-lang.com/documentation/breaking-changes/css-vars
 * 
*/

//media-query
$medium-breakpoint: 1024px;
$small-breakpoint: 768px;

// spacing
$spacing-base-width: 8px;

// fonts

/* beautify ignore:start */
$font-family-base: 'OpenSans-Regular', 'open sans', arial, helvetica, sans-serif;
$font-family-header: 'Sofia Pro', sans-serif !important;
/* beautify ignore:end */

// primary
$color-primary-140: #004646;
$color-primary-120: #005251;
$color-primary-100: #005e5d;
$color-sjøgrønn-mørk: #005e5d;

$color-primary-90: #196d6c;

$color-primary-80: #337e7d;
$color-sjøgrønn-mellom: #337e7d;

$color-primary-60: #669e9d;
$color-primary-40: #99bebe;

$color-primary-20: #ccdfde;
$color-primary-10: #e5eeee;

$color-sjøgrønn-lys: #40c1ac;

// secondary

$color-secondary-100: #ece7d2;
$color-beige-mørj: #ece7d2;

$color-secondary-80: #efebdb;
$color-secondary-60: #f3f0e4;
$color-secondary-40: #f7f5ed;
$color-secondary-20: #fbfaf6;

$color-beige-mellom: $color-secondary-60;
$color-beige-lys: $color-secondary-20;

// gray colors
$color-gray-100: #222222;
$color-gray-80: #4e4e4e;
$color-gray-60: #808080;
$color-gray-40: #a6a6a6;
$color-gray-20: #d2d2d2;
$color-gray-10: #e8e8e8;

// support colors
$color-orange-dark: #d86018;
$color-orange-light: #efbe7d;

// tertiary
$color-blue-dark: #1472ce;
$color-blue-light: #b9d9eb;

$color-pink-dark: #ab5c57;
$color-pink-light: #e6baa8;

$color-yellow-dark: #d69a2d;
$color-yellow-light: #ecd898;

$color-brown-dark: #584446;
$color-brown-light: #d6d2c4;

/*
 * named colors
 * to be used in the application
 *
*/
$color-primary: #005e5d;
$color-primary-variant: $color-primary-80;

$color-secondary: #ece7d2;
$color-secondary-variant: #f3f0e4;

$color-background: #ffffff;
$color-error: #ca0000;
$color-warning: $color-orange-dark;
$color-warning-background: #fce4ca;

$color-success: $color-primary;
$color-text: $color-gray-100;
$color-border: $color-gray-60;
$color-border-variant: $color-gray-20;

// not found in UX color map, but used in UX sketches
$color-link: #00615c;
// $color-table-primary-hover: #ebf3f3; // primary-20?
$color-table-gray-header: #4e4e4e; // gray-80?

$color-focus: $color-primary-80;

$outline-width: 2px;
