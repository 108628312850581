@import './variables.scss';

.flex {
  display: flex;
  gap: $spacing-base-width * 2;

  @for $i from 0 through 6 {
    &.gap-#{$i} {
      gap: $spacing-base-width * $i;
    }
  }

  &.gap-05 {
    gap: calc($spacing-base-width / 2);
  }

  &.justify-end {
    justify-content: flex-end;
  }

  &.direction-column {
    flex-direction: column;
  }

  &.direction-row-reverse {
    flex-direction: row-reverse;
  }

  &.direction-column-reverse {
    flex-direction: column-reverse;
  }

  &.justify-center {
    justify-content: center;
  }

  &.space-between {
    justify-content: space-between;
  }

  &.flex-end {
    justify-content: flex-end;
  }

  &.align-center {
    align-items: center;
  }

  &.align-base {
    align-items: baseline;
  }

  &.flex-wrap {
    flex-wrap: wrap;
  }

  .flex-grow {
    flex-grow: 1;
    // flex-shrink: 0;
  }

  .flex-no-shrink {
    flex-shrink: 0;
  }

  .flex-max-content {
    flex-basis: max-content;
  }

  .flex-align-self-center {
    align-self: center;
  }

  .flex-align-self-start {
    align-self: start;
  }

  @media only screen and (max-width: $medium-breakpoint) {
    &.flex-wrap-m {
      flex-wrap: wrap;
    }
  }

  @media only screen and (max-width: $small-breakpoint) {
    &.flex-wrap-s {
      flex-wrap: wrap;
    }

    .flex-shrink-s {
      flex-grow: 0;
      flex-shrink: 1;
    }
  }
}
