@import './variables.scss';
@import './table.scss';

.data-grid {
  display: flex;
  flex-direction: column;
  gap: $spacing-base-width;
  position: relative;
  overflow-x: auto;
  max-height: 50vh;

  table.table {
    position: relative;

    thead {
      position: sticky;
      top: 0;
      z-index: 2;

      th {
        vertical-align: baseline;

        padding-left: calc($spacing-base-width / 2);
        padding-right: calc($spacing-base-width / 2);

        &:nth-child(1) {
          padding-left: calc($spacing-base-width / 2) * 2;
        }
      }
    }

    tbody {
      tr {
        border: none;
        background: transparent;

        &:last-child {
          border: none;
        }
      }

      td {
        text-align: left;
        vertical-align: baseline;
        padding: calc($spacing-base-width / 2) calc($spacing-base-width / 2);
        height: inherit;

        .td-cell {
          height: 100%;

          &.td-cell-action {
            display: flex;
            align-items: center;
          }
        }
      }

    }
  }

  &.read-only {
    overflow-x: hidden;

    table.table {
      overflow-x: hidden;

      tbody {
        tr:first-child td {
          padding-top: $spacing-base-width;
        }

        td {
          vertical-align: top;
          padding: calc($spacing-base-width / 2) calc($spacing-base-width / 2);

          .td-cell {
            background: white;
            border-radius: 3px;
            padding: 0 calc(($spacing-base-width / 2) * 3);
            display: flex;
            align-items: center;
            margin: 4px 0px;
            border: 1px solid $color-gray-20;
            min-height: 46px;
          }

          &.empty-td {
            padding: calc($spacing-base-width / 2) calc($spacing-base-width / 2);

            .td-empty-cell {
              display: flex;
              gap: $spacing-base-width;
              height: 100%;
              margin: 4px 0px;

              .empty-cell {
                min-height: 46px;
                width: stretch;
                padding: calc($spacing-base-width / 2) ((calc($spacing-base-width / 2)) * 3);
                border: 1px solid $color-gray-20;

                background: $color-background;
                border-radius: 3px;
              }
            }
          }
        }
      }
    }

  }

  &:not(.read-only) {
    table.table tbody td:last-child {
      position: sticky;
      right: -1px;
      padding-right: 0;
      background: linear-gradient(to right, transparent 0%, $color-secondary-60 15%);
    }
  }
}
