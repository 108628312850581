@import './variables.scss';

body {
  font-family: $font-family-base;
  color: $color-text;

  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  line-height: 24px;

  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

a {
  color: $color-primary;

  &:focus {
    outline: $outline-width solid $color-focus;
  }

  // OBS. the inline svg used here is `md-icon-redirect`
  // OBS. the color in fill="color" ($color-primary) is defined as HEX, but # must be escaped with `%23`, hence %23005e5d => #005e5d
  $icon-color: '%23005e5d';
  $icon-redirect: "<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 20 20'><g><polygon points='16.85 16.52 3.15 16.52 3.15 3.52 10.45 3.52 10.45 2.52 2.15 2.52 2.15 17.52 17.85 17.52 17.85 9.86 16.85 9.86 16.85 16.52' fill='#{$icon-color}' /><polygon points='13 3.48 16.21 3.48 10.85 8.84 11.56 9.55 16.85 4.26 16.85 7.34 17.85 7.34 17.85 2.48 13 2.48 13 3.48' fill='#{$icon-color}' /></g></svg>";

  // A HREF that opens a new TAB, needed from UU requirements. see. https://equalizedigital.com/accessibility-checker/link-opens-new-window-or-tab/
  &[target="_blank"]:not(:lang(en))::after {
    content: " (Åpnes i en ny fane) " url("data:image/svg+xml, #{$icon-redirect}");
  }

  &[target="_blank"]:lang(en)::after {
    content: " (Opens in a new tab) " url("data:image/svg+xml, #{$icon-redirect}");
  }
}

* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  outline-color: $color-primary-variant;
}

/* base */
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  font-family: $font-family-header;
}

h1,
h2,
h3,
h4 {
  font-weight: normal;
}

h1 {
  font-size: 34px;
  line-height: 50px !important;
}

h2 {
  font-size: 28px;
  line-height: 42px !important;
}

h3 {
  font-size: 23px;
  line-height: 34px !important;
}

h4 {
  font-size: 20px;
  line-height: 30px !important;
}

h5 {
  font-size: 16px;
  line-height: 24px !important;
}

/* Chrome, Safari, Edge, Opera */

input {
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }

  font-family: $font-family-base;
}

/* Firefox */

::-webkit-input-placeholder {
  font-style: italic;
}

:-moz-placeholder {
  font-style: italic;
}

::-moz-placeholder {
  font-style: italic;
}

:-ms-input-placeholder {
  font-style: italic;
}

input::placeholder {
  font-style: italic;
}
