@import './variables.scss';

@function shade($color, $percentage) {
  @return mix(black, $color, $percentage);
}

.pointer {
  cursor: pointer;
}

.ml-auto {
  margin-left: auto;
}

.align-items-center {
  align-items: center;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}

.scroll-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-everywhere {
  overflow-wrap: anywhere;
}

.break-word {
  word-break: break-word;
}

.text-no-wrap {
  white-space: nowrap;
}

.pre-line {
  white-space: pre-line;
}

.text-overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.alert-text {
  color: $color-error;
  font-size: 14px;
}

.small-text {
  font-size: 14px;
  line-height: 18px;
}

.box-shadow {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

@for $i from 0 through 6 {

  /* margin */
  .m-#{$i} {
    margin: $spacing-base-width * $i;
  }

  .mt-#{$i} {
    margin-top: $spacing-base-width * $i;
  }

  .mb-#{$i} {
    margin-bottom: $spacing-base-width * $i;
  }

  .my-#{$i} {
    margin-top: $spacing-base-width * $i;
    margin-bottom: $spacing-base-width * $i;
  }

  .mr-#{$i} {
    margin-right: $spacing-base-width * $i;
  }

  .ml-#{$i} {
    margin-left: $spacing-base-width * $i;
  }

  .mx-#{$i} {
    margin-left: $spacing-base-width * $i;
    margin-right: $spacing-base-width * $i;
  }

  /* padding */
  .p-#{$i} {
    padding: $spacing-base-width * $i;
  }

  .pt-#{$i} {
    padding-top: $spacing-base-width * $i;
  }

  .pb-#{$i} {
    padding-bottom: $spacing-base-width * $i;
  }

  .py-#{$i} {
    padding-top: $spacing-base-width * $i;
    padding-bottom: $spacing-base-width * $i;
  }

  .pr-#{$i} {
    padding-right: $spacing-base-width * $i;
  }

  .pl-#{$i} {
    padding-left: $spacing-base-width * $i;
  }

  .px-#{$i} {
    padding-left: $spacing-base-width * $i;
    padding-right: $spacing-base-width * $i;
  }
}

.p-05 {
  padding: calc($spacing-base-width / 2);
}

.pt-05 {
  padding-top: calc($spacing-base-width / 2);
}

.pb-05 {
  padding-bottom: calc($spacing-base-width / 2);
}

.py-05 {
  padding-top: calc($spacing-base-width / 2);
  padding-bottom: calc($spacing-base-width / 2);
}

.pr-05 {
  padding-right: calc($spacing-base-width / 2);
}

.pl-05 {
  padding-left: calc($spacing-base-width / 2);
}

.px-05 {
  padding-left: calc($spacing-base-width / 2);
  padding-right: calc($spacing-base-width / 2);
}
