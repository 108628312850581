@import '~@csstools/normalize.css';
@import '~miljodir-wc/src/assets/styles/datagrid.scss';
@import '~miljodir-wc/src/assets/styles/flex.scss';
@import '~miljodir-wc/src/assets/styles/grid.scss';
@import '~miljodir-wc/src/assets/styles/helpers.scss';
@import '~miljodir-wc/src/assets/styles/table.scss';
@import '~miljodir-wc/src/assets/styles/template.scss';
@import 'fonts';
@import 'variables';

body,
html {
  margin: 0;
}

em {
  text-decoration: underline;
  text-decoration-thickness: 0.2em;
  text-decoration-color: $color-primary-variant;
}

.border-bottom {
  border-bottom: 1px solid $color-border;
}

.accordion-container {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

dt {
  font-weight: bold;
}
