@import "./variables.scss";

/*
 * based on https://xd.adobe.com/view/7302919d-1bac-469f-93f4-da4b5ebb35d9-2651/screen/68709f66-ab49-433b-aeb3-021c13212fc3/specs/
 * OBS. default styling is `small` + `primary`
*/
table.table {
  width: 100%;
  border-collapse: collapse;
  font-size: 16px;

  caption {
    caption-side: bottom;
    font-size: 14px;
    padding-top: 8px; // $spacing-base-width ?
  }

  thead {
    background-color: $color-primary;
  }

  tr {
    min-height: 40px;
  }

  tbody tr {
    &:nth-child(odd) {
      background: $color-primary-10; // $color-table-primary-hover;
    }

    &:hover:not([disabled]) {
      background-color: $color-primary-20;
    }

    &[disabled] {
      pointer-events: none;
      opacity: 0.8;
    }
  }

  tbody tr td a {
    color: $color-link;
  }

  th,
  td {
    text-align: left;
    padding: 8px 24px; // what about using $spacing-base-width ?

    &.align-center {
      text-align: center;
    }

    &.align-right {
      text-align: right;
    }
  }

  th {
    color: $color-background;

    a {
      flex-wrap: nowrap;
      display: flex;
      align-items: center;
    }

    &.extra-columns {
      width: 0;
    }
  }

  /* other styles override default attributes */
  &.medium {
    tr {
      min-height: 48px;
    }
  }

  &.large {
    tr {
      min-height: 64px;
    }

    th,
    td {
      padding-left: 32px;
      padding-right: 32px;
    }
  }

  &.secondary {

    /* secondary has never been used, so check it! */
    thead {
      background-color: $color-secondary;
    }

    th {
      color: $color-text;
    }

    tbody tr {
      &:nth-child(odd) {
        background: $color-secondary-20;
      }

      &:hover {
        background-color: $color-secondary-40;
      }
    }
  }

  &.gray {

    /* gray has never been used, so check it! */
    thead {
      background-color: $color-table-gray-header; // gray-80?
    }

    th {
      color: white;
    }

    tbody tr {
      color: $color-text;

      &:nth-child(odd) {
        background: $color-gray-20;
      }

      &:hover {
        background-color: $color-gray-40;
      }
    }
  }
}
