@import './variables.scss';

.grid,
.grid-responsive {
  display: grid;
  gap: $spacing-base-width * 3;
  grid-template-columns: repeat(12, 1fr);

  @for $i from 0 through 4 {
    &.gap-#{$i} {
      gap: $spacing-base-width * $i;
    }
  }
}

@for $i from 0 through 12 {
  @for $y from 0 through 12 {

    // Differs from bootstrap from the point that it specifies the index of a column to start
    // example: col-start-6 col-4 (start at col 6 columns and occupy 4 columns
    .col-start-#{$y}.col-#{$i} {
      grid-column: #{$y + 1} / span #{$i};
    }
  }

  // as in bootstrap
  .col-#{$i} {
    grid-column-start: span #{$i};
  }
}

@media only screen and (max-width: $medium-breakpoint) {
  .grid-responsive {
    display: grid;
    gap: $spacing-base-width * 2;
    grid-template-columns: repeat(8, 1fr);

    @for $i from 0 through 8 {
      @for $y from 0 through 8 {

        // Differs from bootstrap from the point that it specifies the index of a column to start
        // example: col-start-6 col-4 (start at col 6 columns and occupy 4 columns
        .col-m-start-#{$y}.col-m-#{$i} {
          grid-column: #{$y + 1} / span #{$i};
        }
      }

      // as in bootstrap
      .col-m-#{$i} {
        grid-column-start: span #{$i};
      }
    }
  }
}

@media only screen and (max-width: $small-breakpoint) {
  .grid-responsive {
    display: grid;
    gap: $spacing-base-width * 2;
    grid-template-columns: repeat(4, 1fr);

    @for $i from 0 through 4 {
      @for $y from 0 through 4 {

        // Differs from bootstrap from the point that it specifies the index of a column to start
        // example: col-start-6 col-4 (start at col 6 columns and occupy 4 columns
        .col-s-start-#{$y}.col-s-#{$i} {
          grid-column: #{$y + 1} / span #{$i};
        }
      }

      // as in bootstrap
      .col-s-#{$i} {
        grid-column-start: span #{$i};
      }
    }
  }

  .grid-responsive-p {
    padding: $spacing-base-width + calc($spacing-base-width / 2);
  }
}
